import * as React from "react";
import {graphql, Link} from "gatsby";
import {useRef, useEffect, useLayoutEffect, useState, CSSProperties} from "react";
import {Helmet} from "react-helmet";
import Navigation from "../components/Navigation";
import {getBackgroundColorFromName} from "../components/Helpers/Color";
import {createRefAndSetFunction} from "../components/Helpers/RefHelper";
import {useMediaQuery} from "react-responsive";
import Breadcrumb from "../components/Breadcrumb";
import {getPageTitle} from "../components/Helpers/Meta";
import Fade from "../transitions/Fade";
import {off, on, trigger} from "../components/Events";
import Logo from "../components/Logo";
import ScaleText from "../components/ScaleText";
import Lottie from "react-lottie-player";
import * as loadingAnimationData from '../lib/loading.json'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import {getID} from "../components/Helpers/Random";
import ReactTooltip from 'react-tooltip';
import ScrollIndicator from "../components/ScrollIndicator";

const ContactTemplate = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' });
    const data = {
        phone: {
            link: 'tel:+31 76 541 04 04',
            text: '+31 76 541 04 04'
        },
        email: {
            link: 'mailto:welcome@weekend.tk',
            text: 'welcome@weekend.tk'
        },
        address: {
            link: 'https://g.page/weekendcreativeagency',
            text: '<span>Reduitlaan 29</span><span>4814 DC Breda</span>'
        },
        instagram: {
            link: 'https://www.instagram.com/weekendcreativeagency/',
            text: 'shinen op the Gram'
        },
        facebook: {
            link: 'https://www.facebook.com/weekendcreativeagency/',
            text: 'voor mensen die nog<br />wel op Facebook zitten'
        },
        fluff1: {
            link: 'https://careers.handpickedagencies.com/',
            text: 'join our team'
        },
        fluff2: {
            link: 'https://www.youtube.com/c/HorribleReviews',
            text: 'Jeroen’s Youtube'
        }
    };

    const newsletterInputDivRef = useRef(null);
    const newsletterEmailRef = useRef(null);

    const phoneNoContainerRef = useRef(null);
    const phoneNoRef = useRef(null);
    const [initialPhoneNoSize, setInitialPhoneNoSize] = createRefAndSetFunction(null);

    const emailContainerRef = useRef(null);
    const emailRef = useRef(null);
    const [emailInitialSize, setEmailInitialSize] = createRefAndSetFunction(null);

    const gramContainerRef = useRef(null);
    const gramRef = useRef(null);
    const [gramInitialSize, setGramInitialSize] = createRefAndSetFunction(null);

    const joinTeamContainerRef = useRef(null);
    const joinTeamRef = useRef(null);
    const [joinTeamInitialSize, setJoinTeamInitialSize] = createRefAndSetFunction(null);

    const fbContainerRef = useRef(null);
    const fbRef = useRef(null);
    const [fbInitialSize, setFbInitialSize] = createRefAndSetFunction(null);

    const patinderContainerRef = useRef(null);
    const patinderRef = useRef(null);
    const [patinderInitialSize, setPatinderInitialSize] = createRefAndSetFunction(null);

    const jeroenYtContainerRef = useRef(null);
    const jeroenYtRef = useRef(null);
    const [jeroenYtInitialSize, setJeroenYtInitialSize] = createRefAndSetFunction(null);

    const addressContainerRef = useRef(null);
    const addressRef = useRef(null);
    const addressSideTextRef = useRef(null);
    const [addressInitialSize, setAddressInitialSize] = createRefAndSetFunction(null);

    const [timer, setTimer] = createRefAndSetFunction(null);


    const scaleText = (initialSize, containerRef, scaleRef, debug = false) => {
        if (!initialSize.current || !containerRef.current || !scaleRef.current) return;
        const currentRect = {width: scaleRef.current.offsetWidth, height: scaleRef.current.offsetHeight} //initialSize.current;

        if (currentRect.width === 0) currentRect.width = initialSize.current.width;

        const targetRect = containerRef.current.getBoundingClientRect();
        const marginX = 5;

        const scaleX = Math.round(((targetRect.width-marginX) / currentRect.width) * 100) / 100;
        const scaleY = Math.round((targetRect.height / currentRect.height) * 100) / 100;

        scaleRef.current.style.transform = `scale(${scaleX}, ${scaleY})`

        if (debug) {
            console.log(targetRect.width, currentRect, scaleX, initialSize.current.width)
        }
    };

    const scaleAllText = () => {
        scaleText(addressInitialSize, addressContainerRef, addressRef);
        scaleText(initialPhoneNoSize, phoneNoContainerRef, phoneNoRef);
        scaleText(emailInitialSize, emailContainerRef, emailRef);
        scaleText(gramInitialSize, gramContainerRef, gramRef);
        scaleText(joinTeamInitialSize, joinTeamContainerRef, joinTeamRef);
        scaleText(fbInitialSize, fbContainerRef, fbRef);
        scaleText(patinderInitialSize, patinderContainerRef, patinderRef);
        scaleText(jeroenYtInitialSize, jeroenYtContainerRef, jeroenYtRef);
    };

    const onResize = () => {
        if (timer.current) clearTimeout(timer.current)
        setTimer(setTimeout(() => {
            if (timer.current) clearTimeout(timer.current)
            scaleAllText();
            ReactTooltip.rebuild();
        }, 100))
    };

    const setInitialSize = () => {
        if (!initialPhoneNoSize.current) {
            if (phoneNoRef.current) setInitialPhoneNoSize(phoneNoRef.current.getBoundingClientRect());
            if (gramRef.current) setGramInitialSize(gramRef.current.getBoundingClientRect());
            if (emailRef.current) setEmailInitialSize(emailRef.current.getBoundingClientRect());
            if (joinTeamRef.current) setJoinTeamInitialSize(joinTeamRef.current.getBoundingClientRect());
            if (fbRef.current) setFbInitialSize(fbRef.current.getBoundingClientRect());
            if (patinderRef.current) setPatinderInitialSize(patinderRef.current.getBoundingClientRect());
            if (jeroenYtRef.current) setJeroenYtInitialSize(jeroenYtRef.current.getBoundingClientRect());
            if (addressRef.current) setAddressInitialSize(addressSideTextRef.current.getBoundingClientRect());
        }
    }
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const onSubmitNewsletter = (e) => {
        if (!validateEmail(newsletterEmailRef.current.value)) {
            ReactTooltip.show(newsletterEmailRef.current);
            return;
        }

        ReactTooltip.hide(newsletterEmailRef.current);

        newsletterInputDivRef.current.classList.add('loading')

        addToMailchimp(newsletterEmailRef.current.value)
            .then(data => {
                newsletterInputDivRef.current.classList.remove('loading')
                newsletterInputDivRef.current.classList.add('subscribed')
            })
    }

    useLayoutEffect(() => {
        setInitialSize();
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);

        setTimeout(onResize, 100);

        // On component unmount
        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, []);

    useLayoutEffect(() => {
        setInitialSize();
    }, [isTabletOrMobile])

    const hidePage = () => {
        Fade.animate([
            { target: '.o-layout', type: Fade.TYPE_OPACITY }
        ])
    };

    useEffect(() => {
        on('page:out', hidePage)

        return () => {
            off('page:out', hidePage)
        }
    }, [])

    const [counter, setCounter] = useState(0);

    useEffect(() => {
        setCounter(counter+1)
    }, [])

    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {setTimeout(() => {setIsLoaded(true)}, 400)}, []);

    return (
        <>
            <ReactTooltip />
            <Navigation/>
            <Logo breakLapPlus={true} />
            <Helmet>
                <title>{getPageTitle({title: 'Contact'})}</title>
                <style>
                    {`body { background-color: ${getBackgroundColorFromName('geel')} }`}
                </style>

            </Helmet>
            <Breadcrumb text={"Contact"} />

            {isTabletOrMobile ?
                (
                    <div className={"o-layout c-contact--mobile--container"} key={`mobile-container-${counter}-${getID()}`}>
                        <div className={"c-contact--mobile--content"}>
                            <div className={"u-colspan-12 c-breadcrumb"}/>
                            <div className={"u-colspan-12 c-contact--mobile--line c-contact__phoneStyle"}>
                                <ScaleText link={data.phone.link}>
                                    {data.phone.text}
                                </ScaleText>
                            </div>
                            <div className={"u-colspan-12 c-contact--mobile--line c-contact__emailStyle"}>
                                <ScaleText link={data.email.link}>
                                    {data.email.text}
                                </ScaleText>
                            </div>
                            <div className={"u-colspan-12 c-contact--mobile--line-double c-contact--mobile--address"}>
                                <ScaleText link={data.address.link} additionalClassName={"c-contact__addressStyle"}>
                                    <p dangerouslySetInnerHTML={{__html: data.address.text}} />
                                </ScaleText>
                            </div>
                            <div className={"u-colspan-12 c-contact--mobile--line c-contact--mobile--gram"}>
                                <ScaleText link={data.instagram.link} additionalClassName={"c-contact__gramStyle"}>
                                    {data.instagram.text}
                                </ScaleText>
                            </div>
                            <div className={"u-colspan-12 c-contact--mobile--line"}>
                                <ScaleText link={data.fluff2.link} additionalClassName={"c-contact__jeroenYtStyle"}>
                                    {data.fluff2.text}
                                </ScaleText>
                            </div>
                            <div className={"u-colspan-12 c-contact--mobile--line"}>
                                <ScaleText link={data.fluff1.link} additionalClassName={"c-contact__joinTeamStyle"}>
                                    {data.fluff1.text}
                                </ScaleText>
                            </div>

                            <div className={"u-colspan-12 c-contact--mobile--line c-contact__newsletterStyle c-contact--mobile--newsletter-title"}>
                                <ScaleText additionalClassName={" "}>Altijd op de hoogte blijven?</ScaleText>
                            </div>

                            <div className={"u-colspan-12"}>
                                <div className={"c-contact__newsletter-input mobile"} ref={newsletterInputDivRef}>
                                    <input data-event={"none"} data-tip="E-mailadres is onjuist" type={"email"} className={"c-contact__newsletter-text mobile"} placeholder={"Je e-mailadres"} ref={newsletterEmailRef} required />
                                    <button type={"submit"} className={" c-contact__newsletter-submit mobile"}
                                            key={"sign-up-anim"} onClick={onSubmitNewsletter}
                                        onMouseOver={() => trigger('cursor:click')}
                                        onMouseLeave={() => trigger('cursor:unclick')}>
                                        <div className={"msg-sign-up mobile"}>Aanmelden</div>
                                        <Lottie
                                            key={"lottie-anim"}
                                        className={"msg-loading mobile"}
                                        loop={true}
                                        animationData={loadingAnimationData}
                                        play={true}
                                        />
                                        <div className={"msg-thank-you mobile"}>
                                            <span>Bedankt voor je aanmelding. Door inschrijving op onze nieuwsbrief accepteer je dat Weekend je persoonlijke gegevens verwerkt in overeenstemming met het <a href={"/privacy-verklaring"}>privacybeleid</a> van Weekend Creative Agency.</span>
                                        </div>
                                    </button>
                                </div>
                            </div>

                            <div className={"c-contact--mobile--bottom-text"}>
                                <div className={"c-contact--mobile--inner c-contact__fbStyle"}>
                                    <ScaleText link={data.facebook.link}>
                                        <p dangerouslySetInnerHTML={{__html: data.facebook.text}} className={"c-contact__rotateText"} />
                                    </ScaleText>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div className={`o-layout o-layout--full-page c-contact--container ${isLoaded ? 'e-active--delay loaded' : ''}`}
                         key={`desktop-container`}>
                        <div className={"u-colspan-12 u-rowspan-1"} />
                        <div className={"u-colspan-12 u-rowspan-2 c-contact__phoneContainer"}
                             ref={phoneNoContainerRef}>
                            <div className={"c-contact__scaleText"} ref={phoneNoRef}>
                                <a href={data.phone.link} rel={"nofollow"} target={"_blank"}
                                   className={"e-effect e-fade e-move--up-gentle"} style={{'--transition-delay': '1.5s'} as CSSProperties}>
                                    {data.phone.text}
                                </a>
                            </div>
                        </div>
                        <div className={"u-colspan-12 u-rowspan-7"}>
                            <div className={"o-layout u-rowspan-7"}>
                                <div className={"u-colspan-2 u-rowspan-7 c-contact__addressWrapper"}>
                                    <div className={"c-contact__addressContainer"}
                                         ref={addressContainerRef}>
                                        <a href={data.address.link} rel={"nofollow"} target={"_blank"}
                                           onMouseOver={() => trigger('cursor:click')}
                                           onMouseLeave={() => trigger('cursor:unclick')}>
                                        <div className={"c-contact__scaleText"}
                                             ref={addressRef}>
                                            <div className={"c-contact__sideText"}
                                                 ref={addressSideTextRef}
                                                 onMouseOver={() => trigger('cursor:click')}
                                                 onMouseLeave={() => trigger('cursor:unclick')}>
                                                    <p
                                                       className={"e-effect e-fade e-move--down-gentle"}
                                                       style={{'--transition-delay': '1.3s'} as CSSProperties}
                                                       dangerouslySetInnerHTML={{__html: data.address.text}} />
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                </div>
                                <div className={"u-colspan-10 u-rowspan-7"}>
                                    <div className={"o-layout c-contact--right-container"}>
                                        <div className={"u-colspan-12 c-contact__emailContainer"}
                                             ref={emailContainerRef}>
                                            <div className={"c-contact__scaleText"}
                                                 ref={emailRef}>
                                                <a href={data.email.link} rel={"nofollow"} target={"_blank"}
                                                   className={"e-effect e-fade"}
                                                   style={{'--transition-delay': '1.3s'} as CSSProperties}>{data.email.text}</a>
                                            </div>
                                        </div>
                                        <div className={"u-colspan-12 u-rowspan-2 c-contact__newsletterContainer"}>
                                        {/*    Nieuwsbrief*/}
                                        <div className={"c-contact__newsletter"}>
                                            <div className={"c-contact__newsletter-title"} ref={gramContainerRef}>
                                                <div className={"c-contact__scaleText"} ref={gramRef}>
                                                    <div className={"e-effect e-fade e-move--down-gentle"}
                                                         style={{'--transition-delay': '900ms'} as CSSProperties}>Blijf op de hoogte over het tofste weekend nieuws:</div>
                                                </div>
                                            </div>
                                            <div className={"c-contact__newsletter-input e-effect e-fade e-move--up-gentle"} ref={newsletterInputDivRef}>
                                                <input data-event={"none"} data-tip="E-mailadres is onjuist" type={"email"} className={" c-contact__newsletter-text"} placeholder={"Je e-mailadres"} ref={newsletterEmailRef} required/>
                                                <button type={"submit"} className={" c-contact__newsletter-submit"}
                                                        onClick={onSubmitNewsletter}
                                                        onMouseOver={() => trigger('cursor:click')}
                                                        onMouseLeave={() => trigger('cursor:unclick')}>
                                                    <div className={"msg-sign-up"}>Aanmelden</div>
                                                    <Lottie
                                                        className={"msg-loading"}
                                                        loop={true}
                                                        animationData={loadingAnimationData}
                                                        play={true}
                                                    />
                                                    <div className={"msg-thank-you"}>
                                                        <span>Bedankt voor je aanmelding. Door inschrijving op onze nieuwsbrief accepteer je dat Weekend je persoonlijke gegevens verwerkt in overeenstemming met het <a href={"/privacy-verklaring"}>privacybeleid</a> van Weekend Creative Agency.</span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        </div>

                                        <div className={"u-colspan-12"}>
                                            <div className={"o-layout u-columns-24"}>
                                                <div className={"u-colspan-19 u-colspan-20@wall"}>
                                                    <div className={"o-layout"}>
                                                        <div className={"u-colspan-12"}>
                                                            <div className={"o-layout u-columns-24 c-contact__teamFbContainer"}>
                                                                <div className={"u-colspan-15 c-contact__joinTeamContainer"}
                                                                     ref={joinTeamContainerRef}>
                                                                    <div className={"c-contact__scaleText"}
                                                                         ref={joinTeamRef}>
                                                                        <a href={data.instagram.link} rel={"nofollow"} target={"_blank"}
                                                                           className={"e-effect e-fade e-skew"}
                                                                           style={{'--transition-delay': '1.4s'} as CSSProperties}>
                                                                            {data.instagram.text}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div className={"u-colspan-1"} />
                                                                <div className={"u-colspan-8 c-contact__fbContainer"}
                                                                     ref={fbContainerRef}>
                                                                    <div className={"c-contact__scaleText"}
                                                                         ref={fbRef}>
                                                                        <a href={data.facebook.link} rel={"nofollow"} target={"_blank"}
                                                                           onMouseOver={() => trigger('cursor:click')}
                                                                           onMouseLeave={() => trigger('cursor:unclick')}>
                                                                        <div className={"c-contact__rotateText"}>
                                                                            <div className={"e-effect e-fade e-move--up-gentle"}
                                                                                 style={{'--transition-delay': '1.7s'} as CSSProperties}>
                                                                                    <p dangerouslySetInnerHTML={{__html: data.facebook.text}} />
                                                                            </div>
                                                                        </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={"u-colspan-12"}>
                                                            <div className={"o-layout u-columns-24 c-contact__patinderYtLogoContainer"}>
                                                                <div className={"u-colspan-5 u-colspan-7@desk u-colspan-8@wall c-contact__patinderContainer c-contact__joinTeamStyle"}
                                                                     ref={patinderContainerRef}>
                                                                    <div className={"c-contact__scaleText"}
                                                                         ref={patinderRef}>
                                                                        <a href={data.fluff1.link} rel={"nofollow"} target={"_blank"}
                                                                           onMouseOver={() => trigger('cursor:click')}
                                                                           onMouseLeave={() => trigger('cursor:unclick')}>
                                                                            <div className={"e-effect e-fade e-move--down-gentle"}
                                                                                 style={{'--transition-delay': '1.4s'} as CSSProperties}>
                                                                                {data.fluff1.text}
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div className={"u-colspan-14 u-colspan-10@desk u-colspan-8@wall c-contact__logoContainer"}>
                                                                </div>
                                                                <div className={"u-colspan-5 u-colspan-7@desk u-colspan-8@wall c-contact__jeroenYtContainer"}
                                                                     ref={jeroenYtContainerRef}>
                                                                    <div className={"c-contact__scaleText"}
                                                                         ref={jeroenYtRef}>
                                                                        <a href={data.fluff2.link} rel={"nofollow"} target={"_blank"}
                                                                           onMouseOver={() => trigger('cursor:click')}
                                                                           onMouseLeave={() => trigger('cursor:unclick')}>
                                                                            <div className={"e-effect e-fade e-move--down-gentle"} style={{'--transition-delay': '1.5s'} as CSSProperties}>
                                                                                {data.fluff2.text}
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            )}
        </>
    );
};

export default ContactTemplate;
